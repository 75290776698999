import React, { useCallback, useEffect, useState } from "react";
import { getKey, isValidArray } from "../../utils/helpers";
import styled from "styled-components";
import { mediaQ } from "../../theme/Metrics";
import { fetchCurrentCountry } from "../../../server/api/fetchApi";
import AudioPlayerList from "./AudioPlayerList";
import { AudioPlayerSeasonSelector } from "./AudioPlayerSeasonSelector";
import { Colors } from "../../theme";
import AtIcon from "../AtIcon/AtIcon";
import { useAudioPlayer } from "../../contexts/AudioPageContext";

export const PodcastList = ({
  audiosBySeason,
  coverPodcast,
  seasonsPresent,
  geoblocking,
  location,
}) => {
  const { setCurrentIndex } = useAudioPlayer();
  const queryParameters = new URLSearchParams(location.location.search);
  const selectedSeasonRef = React.useRef(null);

  const [audioList, setAudioList] = useState([]);

  useEffect(() => {
    selectedSeasonRef.current =
      queryParameters.get("season") ||
      (isValidArray(Object.entries(seasonsPresent))
        ? Object.entries(seasonsPresent)[0][0]
        : "-1");
  }, []);

  useEffect(() => {
    setAudioList(audiosBySeason[selectedSeasonRef.current]);
  }, [selectedSeasonRef.current]);

  const [isGeoBlocked, setGeoBlocked] = useState(false);

  useEffect(() => {
    async function fetchCountry() {
      let isAbleToPlay = true;
      if (isValidArray(geoblocking)) {
        const currentCountry = await fetchCurrentCountry();
        const currentIsoCode = getKey(
          currentCountry,
          "registered_country.iso_code",
          getKey(currentCountry, "country.iso_code"),
        );
        isAbleToPlay =
          geoblocking.find(
            geoData =>
              currentIsoCode &&
              geoData.value === currentIsoCode.toString().toLowerCase(),
          ) !== undefined;
      }
      setGeoBlocked(!isAbleToPlay);
    }
    fetchCountry();
  }, [geoblocking, setGeoBlocked]);

  const UpdateAudioList = useCallback(value => {
    selectedSeasonRef.current = value;
    setAudioList(audiosBySeason[value]);

    if (typeof window !== "undefined" && window.history) {
      window.history.pushState(null, null, `?season=${value}`);
    }
    setCurrentIndex(-1);
  }, []);
  const formatSeasonData = () => {
    const list = [];
    for (const [idSeason, season] of Object.entries(seasonsPresent)) {
      list.push({
        value: idSeason,
        title: getKey(season, "value", "Sin Categorización"),
      });
    }
    return list;
  };
  const listSeasons = formatSeasonData();

  const toggleSort = () => {
    setAudioList([...audioList].reverse());
    setCurrentIndex(-1);
  };

  return (
    <AudiosContainer>
      <Header>
        <div>
          {listSeasons.length > 1 && (
            <AudioPlayerSeasonSelector
              selectedSeasonRef={selectedSeasonRef}
              seasons={listSeasons}
              onSeasonSelect={UpdateAudioList}
            />
          )}
        </div>
        <Sorter>
          <TotalEpisodes>{audioList.length} cap</TotalEpisodes>
          <SortButton onClick={toggleSort}>
            <AtIcon name="Sort" size={20} color={Colors.darkGray} />
          </SortButton>
        </Sorter>
      </Header>
      <AudiosListContainer>
        {isGeoBlocked ? (
          <ImageBlocked src="https://rtvcplay-v2.s3.amazonaws.com/s3fs-public/2021-02/forbidden.jpg" />
        ) : (
          <AudioPlayerList coverPodcast={coverPodcast} audios={audioList} />
        )}
      </AudiosListContainer>
    </AudiosContainer>
  );
};

const AudiosContainer = styled.div`
  width: 100%;
  margin: 0 auto 0;
  position: relative;

  @media screen and ${mediaQ.tablet} {
    display: flex;
    flex-direction: column;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;

  @media screen and ${mediaQ.tablet} {
    padding: 0 20px;
  }
`;

const Sorter = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const TotalEpisodes = styled.div`
  font-family: Dosis, sans-serif;
  line-height: 20px;
  font-size: 18px;
  color: ${Colors.orangeBright};
`;

const AudiosListContainer = styled.div`
  margin: 20px 0;

  @media screen and ${mediaQ.tablet} {
  }
`;

const ImageBlocked = styled.img`
  width: 100%;
  margin: 20px 0 0;
`;

const SortButton = styled.button`
  display: flex;
  background: transparent;
  border: 2px solid ${Colors.darkGray};
  border-radius: 50%;
  height: 40px;
  width: 40px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    transform: scale(1.1);
    border-color: ${Colors.white};
    path {
      fill: ${Colors.white};
    }
  }
  &:active {
    transform: scale(0.9);
  }
`;
